/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'jquery';
import 'jquery-ujs';
import 'jquery-ui';
import 'jquery-lazyload';
import 'bootstrap';
import 'tag-it';
import 'dropzone';
import 'autoresize-textarea';
import 'clipboard';
import 'stylesheets/application';
import '@fortawesome/fontawesome-free/js/all'
import 'stickyfilljs';
import 'ityped';
import 'js-cookie';

// TODO: Exclude '../../images/specials/movie' images
const images = require.context('../images', true)
global.$ = global.jQuery = require('jquery');
global.Clipboard = require('clipboard');
global.Dropzone = require('dropzone');
global.Cookies = require('js-cookie');

import '../javascripts/header';

import '../javascripts/lazy';
// TODO: Use original ones because all are mostly the same
import '../javascripts/application_cable';
import '../javascripts/cable/subscriptions/appearance';
import '../javascripts/cable/subscriptions/online';
import '../javascripts/cable/subscriptions/notifications';

// Use original files and override functions if needed.
import '../../assets/javascripts/alerts.js.coffee';
import '../../assets/javascripts/notifications/modal_force_term_agreement.js.coffee';
import '../../assets/javascripts/jquery.extend.js';
import '../../assets/javascripts/test-utils.js';
import '../../assets/javascripts/traveloco.js';
import '../../assets/javascripts/modal-area-select.js';
import '../../assets/javascripts/reads.js';
import '../../assets/javascripts/clock.js';
import '../../assets/javascripts/loco/loco_button.js.coffee';
import '../../assets/javascripts/signup_modal.js';
import '../../assets/javascripts/ga4_client.coffee';

import bugsnag from 'bugsnag-js'

if (process.env.BUGSNAG_JS_API_KEY) {
  bugsnag(process.env.BUGSNAG_JS_API_KEY)
}
