jQuery ->
  $('[data-toggle="tooltip"]').tooltip()
  $(document).on 'blur', 'input[type=number]', (ev) ->
    $(ev.target).val($(ev.target).val().replace(/,/, ''))
  $(document).on 'ajax:send', 'form[data-remote=true]', (ev) ->
    $(ev.target).find('input[type=submit]').prop('disabled', true)
  $(document).on 'ajax:success', 'form[data-remote=true]', (ev) ->
    $(ev.target).find('input[type=submit]').prop('disabled', false)
  $(document).on 'ajax:error', 'form[data-remote=true]', (ev) ->
    $(ev.target).find('input[type=submit]').prop('disabled', false)
  $(document).on 'ajax:complete', '.updater', (ev, data) ->
    if $(ev.target).data('update')
      $("##{$(ev.target).data('update')}").replaceWith(data.responseText)
    else if $(ev.target).data('updateclass')
      $(".#{$(ev.target).data('updateclass')}").each (idx, el) ->
        $(el).replaceWith(data.responseText)
    else
      $(ev.target).replaceWith(data.responseText)
  $(document).on 'ajax:success', '.updater', (ev, data) ->
    if $(ev.target).data('update')
      $("##{$(ev.target).data('update')}").replaceWith(data)
    else if $(ev.target).data('updateclass')
      $(".#{$(ev.target).data('updateclass')}").each (idx, el) ->
        $(el).replaceWith(data)
    else
      $(ev.target).replaceWith(data)
  $(document).on 'click', '.remover', (ev) ->
    $("##{$(ev.target).data('target')}").hide()

  $(document).on 'blur', 'input.quote_url', (ev) ->
    form = $(ev.target).parents('form:first')
    $.get($(ev.target).data('href'),
      { url: $(ev.target).val() }, (data) ->
        $(form).find('input.quote_title').val(data.title)
        $(form).find('input.quote_site_name').val(data.site_name)
        $.each(data.images, (idx, image) ->
          $(form).find('.images ul').append("<li><a href='javascript:void(0);'><img src='#{image}' /></a></li>"))
        $(form).find('.images').show()
        $(form).find('.image img').remove()
        $(form).find('.image').append("<img src='#{data.images[0]}' />")
        $(form).find('input.quote_quoting_image_url').val(data.images[0])
      'json'
    )

  $(document).on 'click', 'form.quote .images ul a', (ev) ->
    image = if $(ev.target).find('img:first').attr('src') then $(ev.target).find('img:first') else $(ev.target)
    url = image.attr('src')
    form = $(ev.target).parents('form:first')
    form.find('.image img').attr('src', url)
    form.find('.quote_quoting_image_url').val(url)

   $(document).on 'click', '.popup', (ev) ->
     width = 575
     height = 400
     left = ($(window).width()  - width)  / 2
     top = ($(window).height() - height) / 2
     url = $(ev.target).attr('href')
     opts = 'status=1' + ',width=' + width  + ',height=' + height + ',top=' + top + ',left=' + left
     window.open(url, 'twitter', opts)
     false

  $(document).on 'click', ".btn-update-price-datetime", (ev) ->
    $(ev.target).prev().show()

  $(document).on 'ajax:success', '[data-remote=true]', (ev, data) ->
    message = $($.parseHTML(data)).find('.notification-message').text()
    if message != ""
      $('.message-notifier').text(message).stop().fadeIn(1200).delay(1500).fadeOut("slow")
  $(document).on 'ajax:success', 'form[data-success=close]', (ev) ->
    $(ev.target).slideUp()

  $(document).on 'click', 'ol.message-detail li.collapse', (ev) ->
    if $(ev.target).hasClass('collapse')
      $(ev.target).removeClass('collapse')
    else
      $(ev.target).parents('li:first').removeClass('collapse')
  $(document).on 'click', 'ol.message-detail li .header', (ev) ->
    list = $(ev.target).parents('li:first')
    unless list.hasClass('collapse')
      list.addClass('collapse')

  # Wishの新規作成
  $(document).on 'click', '.add-new-wish', (ev) ->
    $(document).find('.add-new-wish').addClass('collapse')
    $(document).find('.new-wish').removeClass('collapse')
  $(document).on 'click', '.hide-new-wish', (ev) ->
    $(document).find('.add-new-wish').removeClass('collapse')
    $(document).find('.new-wish').addClass('collapse')
  # Wishの編集のフォームを表示
  $(document).on 'click', 'ol.wish-detail li .wish .edit-wish-link', (ev) ->
    show = $(this).parent().parent().parent().find('.edit-wish-show')
    form = $(this).parent().parent().parent().find('.edit-wish-form')
    if form.hasClass('collapse')
      form.removeClass('collapse')
    unless show.hasClass('collapse')
      show.addClass('collapse')
  $(document).on 'click', 'ol.wish-detail li .edit-wish-form .hide-new-wish', (ev) ->
    show = $(this).parent().parent().parent().parent().find('.edit-wish-show')
    form = $(this).parent().parent().parent().parent().find('.edit-wish-form')
    unless form.hasClass('collapse')
      form.addClass('collapse')
    if show.hasClass('collapse')
      show.removeClass('collapse')
  $(document).on 'click', '.delete-wish-form', (ev) ->
    form = $(this).parent().parent().parent().parent().find('.new-wish')
    form.remove()

  $(document).on 'click', '.add_wish_field_in_new_order', (ev) ->
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    $(this).parent().before($(this).data('fields').replace(regexp, time))
    ev.preventDefault()

  $(document).on 'keyup', 'input.price-loco', (ev) ->
    updatePrice(ev)

  $(document).on 'click', 'input.price-loco', (ev) ->
    updatePrice(ev)

  updatePrice = (ev) ->
    if $(ev.target).val() == ""
      $(ev.target).parents('form').find('.price-receive-loco').text("未設定")
      $(ev.target).parents('form').find('.price-receive-loco-unit').hide()
      if $(ev.target).parents('form').find('.price-receive-user')
        $(ev.target).parents('form').find('.price-receive-user').text("未設定")
        $(ev.target).parents('form').find('.price-receive-user-unit').hide()
    else
      price = $(ev.target).val()
      is_campain = $(ev.target).data('is-campain')
      commission_loco = $(ev.target).data('commission-loco')
      commission_user = $(ev.target).data('commission-user')
      purchasing_threshold_price = $(ev.target).data('purchasing-threshold-price')
      over_threshold_commission_loco = $(ev.target).data('over-threshold-commission-loco')
      over_threshold_commission_user = $(ev.target).data('over-threshold-commission-user')
      under_threshold_commission_loco = $(ev.target).data('under-threshold-commission-loco')
      under_threshold_commission_user = $(ev.target).data('under-threshold-commission-user')
      if is_campain
        if price >= purchasing_threshold_price
          commission_loco = over_threshold_commission_loco
          commission_user = over_threshold_commission_user
        else
          commission_loco = under_threshold_commission_loco
          commission_user = under_threshold_commission_user

      price_for_loco = price - parseInt($(ev.target).val() * commission_loco)
      $(ev.target).parents('form').find('.price-receive-loco').text(price_for_loco)
      $(ev.target).parents('form').find('.commission-loco-rate').text((commission_loco * 100).toFixed(1))
      $(ev.target).parents('form').find('.price-receive-loco-unit').show()
      if $(ev.target).parents('form').find('.price-receive-user')
        price_for_user = parseInt($(ev.target).val() * (1 + commission_user))
        $(ev.target).parents('form').find('.price-receive-user').text(price_for_user)
        $(ev.target).parents('form').find('.commission-user-rate').text((commission_user * 100).toFixed(1))
        $(ev.target).parents('form').find('.price-receive-user-unit').show()

  if $('.datepicker').length
    $('.datepicker').datetimepicker(
      locale: 'ja',
      format: 'YYYY/MM/DD'
    )
    $(document).on 'click', '.input-group-addon.calendar', (ev) ->
      # .datepicker is updated by ajax communication
      unless $(ev.target).parents('.input-group').find('.datepicker').data('DateTimePicker')
        $('.datepicker').datetimepicker(
          locale: 'ja',
          format: 'YYYY/MM/DD'
        )
      $(ev.target).parents('.input-group').find('.datepicker').data('DateTimePicker').toggle()

  if $('.timepicker').length
    $('.timepicker').datetimepicker(
      locale: 'en',
      format: 'hh:mm A',
      stepping: 10
    )
    $(document).on 'click', '.input-group-addon.time', (ev) ->
      # .timepicker is updated by ajax communication
      unless $(ev.target).parents('.input-group').find('.timepicker').data('DateTimePicker')
        $('.timepicker').datetimepicker(
          locale: 'en',
          format: 'hh:mm A',
          stepping: 10
        )
      $(ev.target).parents('.input-group').find('.timepicker').data('DateTimePicker').toggle()


  $(document).on 'click', '.show-messages', (ev) ->
    $('ol.message-detail li').removeClass('collapse')
    $(ev.target).hide()
    $('.hide-messages').show()
  $(document).on 'click', '.hide-messages', (ev) ->
    $('ol.message-detail li').addClass('collapse')
    $(ev.target).hide()
    $('.show-messages').show()

  $(document).on 'click', '.open-cancel-form', (ev) ->
    $('.cancel-form').slideDown()
    if $('#modal-cancel-notice').attr('id') == 'modal-cancel-notice'
      $('#modal-cancel-notice').modal()
    $(ev.target).parents('p').hide()
  $(document).on 'click', '.clear-text', (ev) ->
    $(ev.target).parents('.form-group').find('textarea:last').val('')
  $(document).on 'click', '.quote-text', (ev) ->
    textarea = $('#order_message_content')
    texts = $(ev.target).parents('.body').find('.body-content').text().split(/\n/)
    text = []
    for txt in texts
      do (txt) ->
        text.push("> " + txt)
    textarea.val(text.join("\n") + "\n")
    textarea.autoResize()
    textarea.focus()
    $.autoScroll('#order_message_content', 30, 500)
  $(document).on 'focus', '.price-loco', (ev) ->
    $(ev.target).parents('form:first').find('.alert-service-price').slideDown()
  $(document).on 'click', '.btn-link-update-service', (ev) ->
    $('.btn-update-price-datetime').click()
  $(document).on 'click', 'a[href^="#"]' + ':not([data-autoscroll="off"])', (ev) ->
    ev.preventDefault()
    target = $(ev.currentTarget).attr('href')
    unless target == '#'
      marginAdjustment = 30
      headerHeight = if ($('header') && $('header').height()) then $('header').height() else 0
      optAddTopHeight = $(ev.currentTarget).attr('data-autoscroll-top')
      addTop = if optAddTopHeight then Number(optAddTopHeight) else 0
      $.autoScroll('#' + $.escapeSelector(target.slice(1)), headerHeight + marginAdjustment + addTop, 500)
      window.location.hash = target
  if $('.message-notifier').text() != ""
    $('.message-notifier').fadeIn(1200).delay(1500).fadeOut("slow")

  $(document).on 'focus', "#contact_message", (ev) ->
    $(ev.target).addClass('expanded')
  $(document).on 'blur', "#contact_message", (ev) ->
    if $(ev.target).val() == ""
      $(ev.target).removeClass('expanded')

  $('#modal-first-receive-notice').modal('show') if $('#modal-first-receive-notice')

  $('#question_tags').tagit({ fieldName: 'question[tag_names][]', placeholderText: 'トピックを入力しEnter' })

  $(document).on 'click', 'ul#popular_tags li a', (ev) ->
    tag = $(ev.target).text()
    $("#question_tags").tagit("createTag", tag)
    false

  $(document).on 'click', '.profile-languages-popular a', (ev) ->
    $.ajax(
      url: $(ev.target).attr('href'),
      data: { name: $(ev.target).data('name') }
    ).done((data) ->
      if $("##{$(data).attr('id')}").attr('id')
        $("##{$(data).attr('id')}").remove()
      $('.profile-languages').append($(data))
    )
    false
  $(document).on 'click', '.profile-tags-popular a', (ev) ->
    $.ajax(
      url: $(ev.target).attr('href'),
      data: { name: $(ev.target).data('name') }
    ).done((data) ->
      if $("##{$(data).attr('id')}").attr('id')
        $("##{$(data).attr('id')}").remove()
      $('.profile-tags').append($(data))
      $('.question-tags').append($(data))
    )
    false

  $(document).on 'click', '.checked-label a.checked-close', (ev) ->
    $.ajax(
      url: $(ev.target).attr('href'),
      method: $(ev.target).data('method'),
      data: { tag_id: $(ev.target).data('id') })
    $(ev.target).parents('.checked-label').remove()
    false

  $(document).on 'click', '.unset-tag', (ev) ->
    $.ajax(
      url: $(ev.target).attr('href'),
      method: $(ev.target).data('method'),
      data: { tag_id: $(ev.target).data('id') }
    ).done( ->
      $(ev.target).hide()
      $(ev.target).siblings('.set-tag').show()
    )

  $(document).on 'click', '.set-tag', (ev) ->
    $.ajax(
      url: $(ev.target).attr('href'),
      data: { name: $(ev.target).data('name') }
    ).done( ->
      $(ev.target).hide()
      $(ev.target).siblings('.unset-tag').show()
    )
    false

  $(document).on 'ajax:success', 'form#tag_form', (ev, data) ->
    id = $(data).attr('id')
    unless $(ev.target).parents('.autocomplete-wrap').find('.tags').find("##{id}").attr('id')
      $(ev.target).parents('.autocomplete-wrap').find('.tags').append(data)

  $(document).on 'keypress', 'input.input-autocomplete', (ev) ->
    if ev.which == 13
      form = $(ev.target).parents('form:first')
      if $(ev.target).data('updatepath')
        parameters = {
          method: 'get',
          url: $(ev.target).data('updatepath'),
          data: { name: $(ev.target).val() }
        }
      else
        parameters = {
          method: form.attr('method'),
          url: form.attr('action'),
          data: form.serialize(),
        }
      $.ajax(parameters).done((data) ->
        id = $(data).attr('id')
        unless $(ev.target).parents('.autocomplete-wrap').find('.tags').find("##{id}").attr('id')
          $(ev.target).parents('.autocomplete-wrap').find('.tags').append(data)
      )
      $(ev.target).val('')
      false

  $(".clock").clock() if $(".clock").length > 0

  # MEMO: Since the sidebar part is not loaded unless all content areas are read,
  #       lazyload is set to execute only by sidebar.
  $('.lazyload').not('#sidebar-separated .lazyload').lazyload()
  $('#sidebar-separated .lazyload').lazyload()

  $(window).scroll () ->
    if ($(this).scrollTop() > 100)
      $('#backtotop').fadeIn()
    else
      $('#backtotop').fadeOut()
  $(document).on 'click', '#backtotop', () ->
    $.autoScroll()
    false

  $(document).on 'change', 'form.answer-review select', (ev) ->
    $select = $(ev.target)
    $form = $select.parents('form')
    $textarea = $form.find('textarea')
    $submit = $form.find('input[type=submit]')
    if $(ev.target).val() != '' and $textarea.val() != ''
      $submit.prop('disabled', false)
    else
      $submit.prop('disabled', true)
  $(document).on 'keyup', 'form.answer-review textarea', (ev) ->
    $('form.answer-review select').trigger 'change'

  $(document).on 'submit', '#choose_loco_reason', (ev) ->
    $(ev.target).fadeOut("slow", () ->
      $(ev.target).replaceWith("<p>ご協力ありがとうございました</p>").fadeIn('slow'))

  $(document).on 'click', '.becomealoco .all_areas a', (ev) ->
    slug = $(ev.target).attr('href').replace(/\//, '')
    $("#modal-area-select-#{slug}").modal('show')
    false

  $(document).on 'submit', '#dummy_contact_form', (ev) -> false
  $(document).on 'focus', '#contact_message', (ev) -> $('.modal-contact-faqs').hide()
  $(document).on 'blur', '#contact_message', (ev) ->
    if $('#contact_message').val() == ""
      $('.modal-contact-faqs').show()

  $(document).on 'focus', 'textarea', (ev) -> $('.header-mobile').addClass('focus-textarea')
  $(document).on 'blur', 'textarea', (ev) -> $('.header-mobile').removeClass('focus-textarea')

  $('[data-toggle="popover"]').popover()

  $('.message-content-area textarea#order_message_content').autoResize()

  $(document).on 'focus', '#loco_profile_description', (ev) ->
    $('#loco_profile_description_example').slideDown()
  $(document).on 'blur', '#loco_profile_description', (ev) ->
    $('#loco_profile_description_example').slideUp()
  $(document).on 'click', '#btn-notifications-xs', () ->
    $('#modal-notifications').modal()

  new Clipboard('#copy_widget')
  $(document).on 'focus', '#widget_content', (ev) ->
    $('#copy_widget').click()
    $('#copyed_widget').slideDown()

  new Clipboard('#copy_widget_banner')
  $(document).on 'focus', '#widget_banner_content', (ev) ->
    $('#copy_widget_banner').click()
    $('#copyed_widget_banner').slideDown()

  $('#modal-loco-conditions').modal() if $('#modal-loco-conditions')
  $('#modal-fill-form-profile').modal() if $('#modal-fill-form-profile')
  $('#modal-loco-tags').modal() if $('#modal-loco-tags')
  $(document).on 'click' , '#show-modal-fill-profile', () ->
    $('#show-modal-fill-profile').modal()

  $(document).on 'ajax:success', '#modal-fill-form-profile', (ev) ->
    form = $(ev.target).find('form').context
    alert = $(this).find('.modal-body .alert.alert-error p')
    $.ajax(
      method: $(form).attr('method')
      url: $(form).attr('action')
      data: $(form).serialize()
    ).done ((data) ->
      alert.empty()
      error = $(data).find('.alert.alert-danger.alert-error p')
      alert.append(error)
      if alert.text() == ""
        $('#modal-fill-form-profile').modal('hide')
        $('#modal-fill-form-done').modal()
    )

  $('#modal-fill-form-done').on 'show.bs.modal', () ->
    $('#modal-fill-form-done').on 'hidden.bs.modal', () ->
      location.reload()

   $(document).on 'click', '.user-show .loco_question', (ev) ->
     if $(ev.target).hasClass('loco_question')
       target = $(ev.target)
     else
       target = $(ev.target).parents('.loco_question:first')
     $(target).find('.question-detail').show()
     $(target).find('.question-abbr').hide()
     false

  $(document).on 'submit', '.one-submit-only', (e) ->
    $.buttonDisabledSwitch($(e.target).find('input[type="submit"], button'))

  $('#under_area_title .img').css('height', $('#under_area_title .img').width())
  $(window).resize ()->
    $('.img img.avatar-130.photo').css('height', $('.img img.avatar-130.photo').width())

  $(document).on 'keyup', '.realtime-count', () ->
    $('.realtime-counter').text $(this).val().length

  $("#modal-first-time-pay").modal() if $("#modal-first-time-pay")
  $(document).on 'blur', 'form.form-collapse textarea', (ev) ->
    if $(ev.target).val() == ''
      $(ev.target).parents('form').removeClass('on')
  $(document).on 'focus', 'form.form-collapse textarea', (ev) ->
    $(ev.target).parents('form').addClass('on')

  $(document).on 'blur', '.user_login', (ev) ->
    $.post('/signup/account/check', {check_id: $(ev.target).val()}).done (data) ->
      login_error = $('#user_login_error')
      if data?.length
        login_error.empty().parent().addClass('has-error')
        login_error.show()
        $.each(data, (idx, el) ->
          login_error.append('ユーザーID'+data[idx]+'<br/>'))
      else
        login_error.parent().removeClass('has-error').addClass('has-success')
        login_error.hide()
