App.cable.subscriptions.create { channel: "NotificationsChannel" },
  connected: () ->
    $.ajax('/mypage/reads/notifications')
  received: (data) ->
    if data.message
      $('.message-notifier').html(data.message).fadeIn(1200).delay(4000).fadeOut("slow")
    if data.notifications
      $('.menu-notifications-content').html(data.notifications)
      $('a.menu-notifications').removeClass('disabled')
    if data.unreads
      $.each data.unreads, (name, count) ->
        badge = $(".icon-notification-#{name}")
        if badge.length > 0
          if count == 0
            badge.hide()
            badge.parents('a.dropdown-notification').removeClass('active')
          else
            count = "10+" if count > 10
            badge.text(count).show()
            badge.parents('a.dropdown-notification').addClass('active')
            if name == 'total'
              if document.title.match(/^\(/)
                document.title = document.title.replace(/^\([0-9+]+\)/, "(#{count})")
              else
                document.title = "(#{count}) " + document.title
